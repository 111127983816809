import {MIXPANEL_KEYS} from 'analytics/mixpanel'
import {
  IT_HELPDESK,
  APP_MANAGEMENT,
  IDENTITY_MANAGEMENT,
  MOBILE_DEVICE_MANAGEMENT,
  FOR_FOUNDERS_HR_AND_IT_ADMINS,
  BECOME_SECURITY_COMPLIANT,
  SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
  BLOG,
  ABOUT_US,
  CAREERS,
  WHY_HYBR1D,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  X_LINK,
  LINKEDIN_LINK,
  INVENTORY_MANAGEMENT,
  EMPLOYEE_DIRECTORY,
  POLICIES_AND_PERMISSIONS,
  REPORTS_AND_ANALYTICS,
  WORKFLOW_AUTOMATION,
  DOCUMENT_MANGEMENT,
  INTEGRATIONS,
} from 'v1/utils/getters'

import robertImg from 'assets/icons/footer/robert.svg'
import valerianImg from 'assets/icons/footer/valerian.png'
import partickKernImg from 'assets/icons/footer/patrick.png'
import BeamLogo from 'v1/assets/icons/footer/beam-logo-white.svg'
import BRighterAILogo from 'v1/assets/icons/footer/brighterAI-logo-whitesvg.svg'
import EmmyLogo from 'v1/assets/icons/footer/emmy-logo-white.svg'

// Why ZenAdmin icons
import CarShieldIcon from 'v1/assets/icons/footer/car-shield.svg'
import FinanceTrendIcon from 'v1/assets/icons/footer/finance-trend.svg'
import GlobeIcon from 'v1/assets/icons/footer/globe-earth.svg'
import MoneyIcon from 'v1/assets/icons/footer/money.svg'
import CarShieldWhiteIcon from 'v1/assets/icons/footer/car-shield-white.svg'
import FinanceTrendWhiteIcon from 'v1/assets/icons/footer/finance-trend-white.svg'
import GlobeWhiteIcon from 'v1/assets/icons/footer/globe-earth-white.svg'
import MoneyWhiteIcon from 'v1/assets/icons/footer/money-white.svg'

// software icons
import Icon1 from 'v1/assets/icons/footer/logo.svg'
import Icon2 from 'v1/assets/icons/footer/logo-2.svg'
import Icon3 from 'v1/assets/icons/footer/logo-3.svg'
import Icon4 from 'v1/assets/icons/footer/logo-4.svg'
import Icon5 from 'v1/assets/icons/footer/logo-5.svg'
import SlackIcon from 'v1/assets/icons/footer/slack-logo.svg'
import DriveIcon from 'v1/assets/icons/footer/drive-logo.svg'
import MeetIcon from 'v1/assets/icons/footer/meet-logo.svg'
import MsTeamIcon from 'v1/assets/icons/footer/ms-teams-logo.svg'

// social icons
import TwitterIcon from 'v1/assets/icons/footer/twitter-icon.svg'
import FacebookIcon from 'v1/assets/icons/footer/facebook-icon.svg'
import LinkedinIcon from 'v1/assets/icons/footer/linkedin-icon.svg'
import InstagramIcon from 'v1/assets/icons/footer/instagram-icon.svg'
import FooterImage from 'v1/assets/icons/footer/footer-image.webp'

export const footerTestimonialData = {
  topTitle: 'Our clients',
  title: 'Loved by Founders, Admins & HR',
  desc: 'Your one-stop shop for IT Administration & Security workflows along the employee lifecycle.',
  cards: [
    {
      text: `From the moment we started using ZenAdmin, we knew we had made the right choice. The platform's intuitive interface has made it easy for us to procure and keep track of our device inventory. ZenAdmin helped us empower our global workforce to become productive from day 1.”`,
      img: robertImg,
      position: 'Robert Cook, IT Manager',
      companyLogo: BeamLogo,
    },
    {
      text: 'I only regret not outsourcing this topic earlier, hiring ZenAdmin has taken away so much of my headache. What I value most is that we have been able to streamline IT administration processes, remove IT security risks and our internal resources can now concentrate fully on our business again.”',
      img: valerianImg,
      position: 'Valerian Seither, Co-Founder',
      companyLogo: EmmyLogo,
    },
    {
      text: 'ZenAdmin quickly helped us to get tiresome IT Ops processes off my table by taking over IT onboarding & offboarding, application provisioning, and device security. This frees up many hours to focus on our clients.”',
      img: partickKernImg,
      position: 'Patrick Kern, CTO',
      companyLogo: BRighterAILogo,
    },
  ],
}

export const whyZenAdminData = {
  title: 'Why ZenAdmin',
  subTitle: 'An easier way to power your company growth',
  cards: [
    {
      icon: GlobeIcon,
      activeIcon: GlobeWhiteIcon,
      heading: 'Boost Productivity',
      content: 'Expand your operations into new countries with ease.',
    },
    {
      icon: FinanceTrendIcon,
      activeIcon: FinanceTrendWhiteIcon,
      heading: 'Increase Efficiency',
      content: 'Significantly reduce the administrative load on your HR and legal departments.',
    },
    {
      icon: CarShieldIcon,
      activeIcon: CarShieldWhiteIcon,
      heading: 'Improve Performance',
      content:
        'Minimize the risk of non-compliance penalties with proactive legal checks and balances.',
    },
    {
      icon: MoneyIcon,
      activeIcon: MoneyWhiteIcon,
      heading: 'Revenue Growth',
      content: 'Ensure consistent adherence to international labor laws.',
    },
  ],
}

export const softwareIcons = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  SlackIcon,
  DriveIcon,
  MeetIcon,
  MsTeamIcon,
]
export const footerHeaderData = {
  logo: '',
  leftText:
    'Magically simplify the way you manage your HR & IT- from payroll, benefit & expenses, to employee devices and apps',
  rightText: 'Sign up for a free resource newsletter',
}

export const footerProductsData = {
  heading: 'Products',
  list: [
    [
      {
        title: 'Zen IT',
        subList: [
          {name: 'Inventory Management', link: INVENTORY_MANAGEMENT},
          {name: 'SaaS Management', link: APP_MANAGEMENT},
          {name: 'Identity & Access Management', link: IDENTITY_MANAGEMENT},
          {name: 'Mobile Device Management', link: MOBILE_DEVICE_MANAGEMENT},
          {name: 'IT Helpdesk', link: IT_HELPDESK},
          // {name: 'Integrations', link: INTEGRATIONS},
        ],
      },
    ],
    [
      {
        title: 'Zen Core',
        subList: [
          {name: 'Employee Directory', link: EMPLOYEE_DIRECTORY},
          {name: 'Document Management ', link: DOCUMENT_MANGEMENT},
          {name: 'Workflow Automation', link: WORKFLOW_AUTOMATION},
          {name: 'Report and Analytics', link: REPORTS_AND_ANALYTICS},
          {name: 'Policy & Permissions', link: POLICIES_AND_PERMISSIONS},
          {name: 'Employee Experience', link: ''},
        ],
      },
    ],
    // [
    //   {
    //     title: 'Zen HR Management',
    //     subList: [
    //       {name: 'Global payroll hub', link: ''},
    //       {name: 'Benefit Admnisitration', link: ''},
    //       {name: 'Time & Attendance', link: ''},
    //       {name: 'Time off', link: ''},
    //       {name: 'Contractor management', link: ''},
    //       {name: 'Zen PEO services', link: ''},
    //       {name: 'EOR services', link: ''},
    //       {
    //         name: 'Performance Management',
    //         flag: 'coming-later',
    //         flagText: '🚀 Coming later this year',
    //       },
    //     ],
    //   },
    // ],
    // [
    //   {
    //     title: 'Zen Payroll',
    //     subList: [
    //       {name: 'Pleminary payroll', link: ''},
    //       {name: 'Global Payroll', link: ''},
    //       {name: 'US payroll', link: ''},
    //     ],
    //   },
    // ],
    // [
    //   {
    //     title: 'Zen EX Employee Expearience',
    //     subList: [
    //       {name: 'Survey', flag: 'coming-later', flagText: '🚀 Coming later this year'},
    //       {name: 'AI HR companion on Slack and Teams', link: ''},
    //       {name: 'AI IT companion on Slack and Teams', link: ''},
    //       {name: 'AI Knowledge management', link: ''},
    //     ],
    //   },
    // ],
  ],
}

export const footerSolutionsData = {
  heading: 'Solutions',
  list: [
    [
      {
        subList: [{name: 'For Founders, HR and IT Admins', link: FOR_FOUNDERS_HR_AND_IT_ADMINS}],
      },
    ],
    [
      {
        subList: [{name: 'Become Security Compliant', link: BECOME_SECURITY_COMPLIANT}],
      },
    ],
    [
      {
        subList: [
          {
            name: 'Save Costs & Establish Scalable Processes',
            link: SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES,
          },
        ],
      },
    ],
  ],
}

export const footerCompanyData = {
  heading: 'Company',
  list: [
    [
      {
        subList: [{name: 'About us', link: ABOUT_US}],
      },
    ],
    [
      {
        subList: [{name: 'Blog', link: BLOG}],
      },
    ],
    // [
    //   {
    //     subList: [{name: 'Career', flag: 'hiring', flagText: 'Hiring', link: CAREERS}],
    //   },
    // ],
    [
      {
        subList: [{name: 'Why ZenAdmin', link: WHY_HYBR1D}],
      },
    ],
  ],
}

export const footerAddressData = {
  heading: 'Address',
  list: [
    [
      {
        title: 'Germany',
        subList: [{name: 'Leibnizstraße 58, 10629 Berlin, DEHRB236794B'}],
      },
      {
        title: 'India',
        subList: [{name: 'HD-128, 43, WeWork Galaxy, Ashok Nagar, Bengaluru, 560025'}],
      },
    ],
    [
      {
        title: 'Singapore',
        subList: [{name: '160 Robinson Rd #14-04, Singapore - 068914'}],
      },
      {
        title: 'Hong Kong',
        subList: [{name: 'Unit 2A 17/F, Glenealy Tower No. 1, Glenealy Central, HK - 999077'}],
      },
    ],
    [
      {
        title: 'Texas',
        subList: [{name: '25298 FM 2978 RD unit A, Tomball, TX, 77375-2504, US'}],
      },
      {
        title: 'State of Washington',
        subList: [{name: '4505 Pacific HWY E STE C-2, FIFE, WA, 98424, US'}],
      },
    ],
  ],
}

export const bottomFooterData = {
  copyrightText: '© Hybr1d Technologies Pte Ltd. , Singapore',
  menu: [
    {
      title: 'Terms of Service',
      href: TERMS_OF_SERVICE,
      eventKey: MIXPANEL_KEYS.TERMS_OF_SERVICE,
    },
    {
      title: 'Privacy Policy',
      href: PRIVACY_POLICY,
      eventKey: MIXPANEL_KEYS.PRIVACY_POLICY,
    },
  ],
  socialMedia: [
    {name: 'twitter', icon: TwitterIcon, link: X_LINK},
    // {name: 'facebook', icon: FacebookIcon, link: ''},
    {name: 'linkedin', icon: LinkedinIcon, link: LINKEDIN_LINK},
    // {name: 'instagram', icon: InstagramIcon, link: ''},
  ],
  footerHiddenImgSrc: FooterImage,
}
