import Image from 'next/image'
import Link from 'next/link'
import {DEMO_LINK} from 'v1/utils/getters'
import Button from 'v1/common-components/button/Button'
import CheckedCircleFilled from 'v1/assets/icons/ga/checked-circle-filled.svg'
import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import classes from './styles.module.css'

interface Props {
  isFreeTrialPage?: boolean
  title?: string
  paragraph?: string
  ctaText?: string
}

export default function PickASlotCard({isFreeTrialPage, title, paragraph, ctaText}: Props) {
  return (
    <div className={classes.pickSlotCard}>
      <Image
        loading="lazy"
        src={CheckedCircleFilled}
        className={classes.bookedSlotIcon}
        alt="icon"
      />
      <h4 className="h4-v1">
        Thank you
        {title ? (
          <>
            !<br />
            {title}
          </>
        ) : !isFreeTrialPage ? (
          ' for booking a demo!'
        ) : (
          ''
        )}
      </h4>
      <p className="paragraph1-v1" style={{marginTop: '16px'}}>
        {paragraph ||
          'Our team will contact you shortly. Meanwhile, you can schedule a convenient time for the demo.'}
      </p>
      <Link rel="preload" href={DEMO_LINK} target="_blank" className={classes.bookedDemoBtn}>
        <Button
          text={ctaText || 'Pick a slot'}
          className={classes.submitButton}
          iconRight={
            <span className={classes.btnIconBox}>
              <Image loading="lazy" src={ArrowRightIcon} alt="login-icon" width={24} height={24} />
            </span>
          }
          isAnimate={true}
        />
      </Link>{' '}
    </div>
  )
}
